import { LOCAL_STORAGE } from '../constants/local-storage';

export const getUtmCampaign = () => {
  const urlParams = new URLSearchParams(window.location.search);

  const setUtmLocalStorage = (utm: string, localStorageValue: string) => {
    if (urlParams.has(utm)) {
      const utmValue = urlParams.get(utm);

      if (utmValue && !localStorage.getItem(localStorageValue))
        localStorage.setItem(localStorageValue, utmValue);
    }
  };

  setUtmLocalStorage('fingerprint', LOCAL_STORAGE.xFingerprint);

  if (urlParams.has('utm_source')) {
    const utmSource = urlParams.get('utm_source');

    if (utmSource) localStorage.setItem('utm_source', utmSource);
  }

  if (urlParams.has('clickid')) {
    const clickid = urlParams.get('clickid');

    if (clickid) localStorage.setItem('clickid', clickid);
  }

  if (urlParams.has('utm_campaign')) {
    const utmCampaign = urlParams.get('utm_campaign');

    if (utmCampaign && !localStorage.getItem(LOCAL_STORAGE.refCode)) {
      localStorage.setItem(LOCAL_STORAGE.refCode, utmCampaign);
      localStorage.setItem(LOCAL_STORAGE.utm_campaign, utmCampaign);

      setUtmLocalStorage('utm_medium', LOCAL_STORAGE.utm_medium);
      setUtmLocalStorage('utm_term', LOCAL_STORAGE.utm_term);
      setUtmLocalStorage('utm_content', LOCAL_STORAGE.utm_content);
      setUtmLocalStorage('gclid', LOCAL_STORAGE.gclid);
      setUtmLocalStorage('fbclid', LOCAL_STORAGE.fbclid);
      setUtmLocalStorage('ttclid', LOCAL_STORAGE.ttclid);
    }
  }

  const isGaidInParams = urlParams.has('gaid');
  const isGaidInLs = !!localStorage.getItem(LOCAL_STORAGE.gaid);

  setUtmLocalStorage('gaid', LOCAL_STORAGE.gaid);

  if (!isGaidInParams && !isGaidInLs) {
    const gaidCookie = document.cookie
      ?.split(';')
      ?.find((item) => item.trim().startsWith('_ga='))
      ?.trim()
      ?.slice(4);

    if (gaidCookie) {
      localStorage.setItem(LOCAL_STORAGE.gaid, gaidCookie);
    }
  }
};
