import { Provider } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { HistoryRouter as Router } from 'redux-first-history/rr6';
import { ReactNode, useEffect } from 'react';

import { store, history } from './redux/configure-store';
import { CustomNoticeBar } from './components/custom-notice-bar';
import { useScrollToTop } from './hooks/use-scroll-to-top';
import { routes } from './routes/routes';
import { getNativeDeviceInfo } from './hooks/use-get-native-info';
import './app.css';
import { usePinTimers } from './hooks/use-pin-timers';
import { getUtmCampaign } from './hooks/use-utm-campaign';
import { useFingerprint } from './hooks/use-fingerprint';
import { isWebContext } from './utils/is-web-context';
import { useAppSelector } from './hooks/use-redux-hook';
import { deviceNativeInfoSelector } from './redux/modules/app';

const ScrollToTopWrapper = ({ children }: { children: ReactNode | ReactNode[] }) => {
  const { pathname } = useLocation();

  useScrollToTop(pathname);
  usePinTimers();
  getUtmCampaign();
  useFingerprint();

  return children;
};

const NativeWrapper = ({ children }: { children: ReactNode | ReactNode[] }) => {
  const { appVersion } = useAppSelector(deviceNativeInfoSelector);

  useEffect(() => {
    if (window) {
      getNativeDeviceInfo();
    }
  }, []);

  if (!isWebContext && appVersion) {
    return children;
  }
  if (isWebContext) {
    return children;
  }

  return null;
};

export const App = () => (
  <Provider store={store}>
    <Router history={history}>
      <NativeWrapper>
        <ScrollToTopWrapper>
          {routes}
          <CustomNoticeBar />
        </ScrollToTopWrapper>
      </NativeWrapper>
    </Router>
  </Provider>
);
