export enum ApiGroupNames {
  AUTH = 'auth',
  REGISTRATION = 'registration',
  LOAN = 'loan',
  USER = 'user',
  NUBARIUM = 'nubarium',
  APPLICATION = 'application',
  APPLICATION_ACCEPT = 'application-accept',
  APPLICATION_DECLINE = 'application-decline',
  APPLICATION_CANCEL = 'application-cancel',
  JUMIO = 'jumio',
  RECOVERY = 'recovery',
  CHAT = 'chat',
  UNDERWRITING = 'underwriting',
  ENRICHMENT = 'enrichment',
}

export enum EndpointQueryNames {
  DEV_MAIN = 'devMain',
  DEV_LOAN_ORIGINATION = 'devLoanOrigination',
  DEV_USER_MANAGEMENT = 'devUserManagement',
  DEV_LOAN_MANAGEMENT = 'devLoanManagement',
  DEV_NUBARIUM = 'devNubarium',
  MOCK = 'mock',
  LOCAL_MOCK = 'localMock',
}
