import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Paths } from '../routes/paths-enum';

export const useBackButton = (redirectPath: string) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    if (
      [
        Paths.HOME as string,
        Paths.CREATE_PASSWORD as string,
        Paths.INTRO_SCREEN as string,
        Paths.JUMIO as string,
        Paths.SCAN_INE_CARD as string,
        Paths.DESTINATION_ACCOUNT as string,
        Paths.ID_INFO as string,
        Paths.PERSONAL_INFO as string,
        Paths.PROCESSING_APP as string,
        Paths.APPLICATION_APPROVED as string,
        Paths.RESULT_PAGE as string,
        Paths.REQUEST_STATUS as string,
        Paths.ENRICHMENT_PAGE as string,
      ].includes(pathname)
    ) {
      window.history.pushState(null, '', window.location.pathname);

      const handlePopState = () => {
        navigate(redirectPath, { replace: true });
      };

      window.addEventListener('popstate', handlePopState);

      return () => {
        window.removeEventListener('popstate', handlePopState);
      };
    }
  }, [pathname, navigate, redirectPath]);
};
