import uuid from 'react-uuid';
import {
  BaseQueryApi,
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query';
import platform from 'platform';

import { API_LOCAL_MOCK_URL, API_MOCK_URL, LOCAL_MOCK } from '../../constants/general';
import { HttpStatus } from '../../constants/http-status';
import { COOKIE_STORAGE, LOCAL_STORAGE } from '../../constants/local-storage';
import { ApiAuthResponse } from '../../types/api';
import { ApiEndpoints } from '../constants/api';
import { EndpointQueryNames } from '../constants/api-group-names';
import { clearStateOnLogout } from '../modules/app';
import { store } from '../configure-store';
import { setTokensToCookie, getCookie, removeCookies } from '../../utils/cookie-parser';
import { getEnvUrl } from '../../utils/get-env-url';
import { removeSessionStorageItem } from '../../utils/storage-handlers';
import AppsFlyer from '../../utils/appsflyer';
import { env } from '../../constants/app-url';

import { apiSlice } from '.';

const keyCloakBaseQuery = fetchBaseQuery({
  baseUrl: LOCAL_MOCK ? API_LOCAL_MOCK_URL : getEnvUrl().apiObject.REACT_APP_BASE_URL,
  prepareHeaders: (headers) => {
    headers.set('Content-Type', 'application/x-www-form-urlencoded');
  },
});

const dynamicBaseQuery = async (
  args: any,
  api: BaseQueryApi,
  extraOptions: { auth?: boolean | undefined },
) => {
  const getBaseQuery = () => {
    if (LOCAL_MOCK) {
      return API_LOCAL_MOCK_URL;
    }

    // TODO: remove local constants
    const currentUrl = getEnvUrl().apiObject;

    switch (args.endpoint) {
      case EndpointQueryNames.DEV_LOAN_ORIGINATION:
        return currentUrl.REACT_APP_BASE_LOAN_URL;
      case EndpointQueryNames.DEV_USER_MANAGEMENT:
        return currentUrl.REACT_APP_BASE_USER_URL;
      case EndpointQueryNames.DEV_MAIN:
        return currentUrl.REACT_APP_BASE_URL;
      case EndpointQueryNames.LOCAL_MOCK:
        return API_LOCAL_MOCK_URL;
      case EndpointQueryNames.DEV_NUBARIUM:
        return currentUrl.REACT_APP_BASE_NUBARIUM_URL;
      case EndpointQueryNames.DEV_LOAN_MANAGEMENT:
        return currentUrl.REACT_APP_BASE_LOAN_DETAIL_URL;
      default:
        return API_MOCK_URL;
    }
  };

  const mainBaseQuery = fetchBaseQuery({
    baseUrl: getBaseQuery(),
    prepareHeaders: (headers) => {
      const devEnv = getEnvUrl().currentEnv === env.DEV;
      const xSessionId = sessionStorage.getItem(LOCAL_STORAGE.xSessionId);
      const accessToken = getCookie('accessToken');
      const xFingerprint = localStorage.getItem(LOCAL_STORAGE.xFingerprint) || uuid();
      const xFingerprintVisitorId = localStorage.getItem(LOCAL_STORAGE.xFingerprintVisitorId);

      const osVersion = platform.os?.version;
      const defaultOsFamily = platform.os?.family;
      let osFamily = null;

      if (defaultOsFamily === 'Android') {
        osFamily = 'ANDROID';
      } else if (defaultOsFamily === 'iOS') {
        osFamily = 'IOS';
      } else if (defaultOsFamily !== null) {
        osFamily = 'WEB';
      }

      if (osVersion) {
        headers.set('X-OS-Version', osVersion);
      }
      if (osFamily) {
        headers.set('X-Platform-Type', osFamily);
      }
      // TODO spam
      if (accessToken && accessToken.length > 5) {
        headers.set('authorization', `Bearer ${accessToken}`);
      }
      if (xSessionId) {
        headers.set('X-Session-Id', xSessionId);
      }

      const randomPart = () => Math.floor(Math.random() * 256);
      const xForwardedFor = `${randomPart()}.${randomPart()}.${randomPart()}.${randomPart()}`;

      if (xForwardedFor && devEnv) {
        headers.set('X-Forwarded-For', xForwardedFor);
      }

      if (xFingerprint) {
        headers.set('X-Fingerprint', xFingerprint);
        localStorage.setItem(LOCAL_STORAGE.xFingerprint, xFingerprint);
      }
      if (xFingerprintVisitorId) {
        headers.set('X-Fingerprint-Visitor-Id', xFingerprintVisitorId);
      }

      return headers;
    },
  });

  return mainBaseQuery(args, api, extraOptions);
};

export const baseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions: { auth?: boolean },
) => {
  const { dispatch } = api;
  const logOut = (clearStorage = true) => {
    removeSessionStorageItem(LOCAL_STORAGE.xSessionId);
    removeCookies([COOKIE_STORAGE.accessToken, COOKIE_STORAGE.refreshToken]);
    removeSessionStorageItem(LOCAL_STORAGE.isAuthorized);
    // history.replace(Paths.MAIN);
    if (clearStorage) {
      setTimeout(() => {
        dispatch(clearStateOnLogout());
        dispatch(apiSlice.util.resetApiState());
        removeSessionStorageItem(LOCAL_STORAGE.userLastActivityTime);
      }, 0);
    }
  };

  const logIn = (response: ApiAuthResponse) => {
    try {
      setTokensToCookie(response);
      sessionStorage.setItem(LOCAL_STORAGE.isAuthorized, 'true');
    } catch {
      logOut();
    }
  };

  if (extraOptions?.auth) {
    const authResult = await keyCloakBaseQuery(args, api, extraOptions);

    if (authResult.data) {
      const response = authResult.data as ApiAuthResponse;

      logIn(response);
    }

    // if (authResult.error) {
    //   logOut();
    // }

    const authHeaders = authResult.meta?.response?.headers;
    const headersObject: { [key: string]: string } = {};

    authHeaders?.forEach((value, key) => {
      headersObject[key] = value;
    });
    const xSessionIdHeader = headersObject['x-session-id'];

    if (xSessionIdHeader) {
      sessionStorage.setItem(LOCAL_STORAGE.xSessionId, xSessionIdHeader);
      AppsFlyer.setCustomerUserId();
    }

    return authResult;
  }

  let result = await dynamicBaseQuery(args, api, extraOptions);

  if (result.error && result.error.status === HttpStatus.UNAUTHORIZED) {
    const refreshToken = getCookie(COOKIE_STORAGE.refreshToken);

    if (refreshToken) {
      const refreshResult = await keyCloakBaseQuery(
        {
          url: ApiEndpoints.REFRESH_TOKEN,
          method: 'POST',
          body: new URLSearchParams({
            refreshToken,
          }),
        },
        api,
        extraOptions,
      );
      const isExistPinLs = localStorage.getItem(LOCAL_STORAGE.pinRefreshToken);
      const response = refreshResult?.data as ApiAuthResponse;

      if (isExistPinLs && response?.refreshToken) {
        const { pinHandler } = store.getState().app;

        pinHandler(response?.refreshToken);
        const expireMs = (response?.refreshExpiresIn || 1) * 1000;
        const timer = Date.now() + expireMs;

        localStorage.setItem(LOCAL_STORAGE.incorrectPinCounter, '0');
        localStorage.setItem(LOCAL_STORAGE.refreshTimer, timer.toString());
        localStorage.removeItem(LOCAL_STORAGE.incorrectPinTimer);
      }

      if (refreshResult.data) {
        logIn(response);
        result = await dynamicBaseQuery(args, api, extraOptions);
      } else {
        logOut(api.endpoint !== 'getCalculator');
      }
    } else {
      logOut(api.endpoint !== 'getCalculator');
    }
  }

  const responseHeaders = result.meta?.response?.headers;
  const headersObject: { [key: string]: string } = {};

  responseHeaders?.forEach((value, key) => {
    headersObject[key] = value;
  });
  const xSessionIdHeader = headersObject['x-session-id'];

  if (xSessionIdHeader) {
    sessionStorage.setItem(LOCAL_STORAGE.xSessionId, xSessionIdHeader);
    AppsFlyer.setCustomerUserId();
  }

  return result;
};
