export const LOCAL_STORAGE = {
  xSessionId: 'x-session-id',
  incorrectPinCounter: 'incorrectPinCounter',
  pinRefreshToken: 'pinRefreshToken',
  salt: 'salt',
  xFingerprint: 'x-fingerprint',
  userLastActivityTime: 'userLastActivityTime',
  refreshTimer: 'refreshTimer',
  incorrectPinTimer: 'incorrectPinTimer',
  isAuthorized: 'isAuthorized',
  eqId: 'eqId',
  refCode: 'refCode',
  biometrics: 'biometrics',
  bioRefreshTimer: 'bioRefreshTimer',
  getFirstLoan: 'getFirstLoan',
  deviceId: 'deviceId',
  platform: 'platform ',
  savedPushToken: 'savedPushToken',
  utm_campaign: 'utm_campaign',
  utm_source: 'utm_source',
  utm_medium: 'utm_medium',
  utm_term: 'utm_term',
  utm_content: 'utm_content',
  gclid: 'gclid',
  fbclid: 'fbclid',
  ttclid: 'ttclid',
  gaid: 'gaid',
  xFingerprintVisitorId: 'x-fingerprint-visitor-id',
  clickid: 'clickid',
  isApprovedScreenShown: 'isApprovedScreenShown',
  isNewClient: 'isNewClient',
  isNotFirst: 'isNotFirst',
  analytics: 'analytics',
};

export const COOKIE_STORAGE = {
  refreshToken: 'refreshToken',
  accessToken: 'accessToken',
  idToken: 'idToken',
};
