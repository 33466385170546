export const Paths = {
  MAIN: '/',
  PUSH_MAIN: '/main',
  HOME: '/home',
  AUTH: '/auth',
  PASSWORD_RECOVERY: '/password-recovery',
  NEW_PASSWORD: '/new-password',
  REGISTRATION: '/registration',
  RESULT: '/result',
  CONTACT_INFO: '/contact-info',
  CREATE_PASSWORD: '/create-password',
  CALCULATOR: '/calculator',
  APP: '/app',
  CHAT: '/chat',
  HISTORY: '/history',
  PROFILE: '/profile',
  LOAN_APPLICATION: '/loan-application',
  SCAN_INE_CARD: '/scan-ine',
  PERSONAL_INFO: '/personal-info',
  PERSONAL_INFO_ADDRESS: '/personal-info-address',
  ID_INFO: '/id-info',
  ID_INFO_ADDRESS: '/id-info-address',
  INTRO_SCREEN: '/intro-screen',
  APPLICATION_APPROVED: '/application-approved',
  REQUEST_STATUS: '/request-status',
  DUPLICATE_PHONE_NUMBER: '/duplicate-phone-number',
  RESULT_PAGE: '/result-page',
  PDF_VIEWER: '/pdf-viewer',
  LOAN_DETAILS: '/loan-details',
  LOAN_PAYMENT: '/loan-payment',
  LOAN_REPAY_PROVIDER: '/loan-repay',
  JUMIO: '/jumio',
  DESTINATION_ACCOUNT: '/destination-account',
  CONTACT_DATA: '/contact-data',
  PERSONAL_DATA: '/personal-data',
  SETTINGS: '/settings',
  CHANGE_PASSWORD: '/change-password',
  BIOMETRIC: '/biometric',
  PIN: '/pin',
  PROCESSING_APP: '/processing-app',
  CONFIRM_EMAIL: '/verification-email',
  FAQ: 'https://monetech.mx/faq',
  ADDITIONAL_DATA_SCREEN_PAGE: '/additional-data',
  UNDERWRITING_CAM: '/underwriting',
  IFRAME: '/frame',
  IFRAME_PROTECTED: '/frame-authenticated',
  ENRICHMENT_PAGE: '/enrichment-page',
  ENRICHMENT_FORM_PAGE: '/enrichment-form-page',
  ENRICHMENT_ADDRESS_PAGE: '/enrichment-address-page',
} as const;
