export const esTranslation = {
  yes: 'Sí',
  no: 'No',
  notFoundPage: {
    title: 'Página no encontrada',
    buttonTitle: 'Página no encontrada',
  },
  errorPage: {
    title: 'Página de error',
    anErrorOccurred: 'Ha ocurrido un error',
    somethingWentWrong: 'Algo salió mal...',
  },
  calculator: {
    title: 'Calculadora de préstamos',
    titleButtonTake: 'Solicitar un préstamo',
    titleButtonApply: 'Obtener',
    borrowedAmount: 'Cantidad a solicitar',
    repaymentPeriod: 'Periodo de pago',
    days: 'días',
    calculation: 'Cálculo',
    maturityDate: 'Fecha de pago',
    repaymentAmount: 'Monto a pagar',
    calculationDescription:
      'Los montos calculados son preliminares y se finalizarán después de completar la solicitud de préstamo',
    letYourDreams: 'Haz tus sueños realidad, es fácil',
    freeLoan:
      'Felicitaciones, tu préstamo ahora está libre de intereses y cargos, ¡solo devuelves lo que tomas!',
    interestRate: 'Tasa de interés',
    commissionDescription1: '* Incluye IVA',
    commissionDescription2: 'MXN',
    commissionDescription3: '** CAT 400%',
    pleaseUploadDocuments: 'Por favor cargue los documentos adicionales necesarios',
  },
  authorization: {
    title: 'Iniciar sesión',
    button: 'Iniciar sesión',
    phoneInput: 'Número de teléfono',
    passwordInput: 'Contraseña',
    phoneError: 'Ingresa el número de teléfono',
    passwordInputPlaceholder: 'Ingresa la contraseña',
    forgotYourPassword: '¿Olvidaste tu contraseña?',
  },
  registration: {
    title: 'Registrarse',
    titlePassRecovery: 'Recuperación de contraseña',
    smsCodeConfirmation: 'Confirmación de código SMS',
    inputLabel: 'Número de teléfono',
    agreements: {
      personalData: 'Consiento el procesamiento de mis datos personales',
      loanBureau: 'Consiento la solicitud para acceder a mi historial crediticio',
      ownBehalf: 'Actúo en mi propio nombre',
    },
    pep: {
      title:
        '¿Usted o alguno de sus familiares desempeña o ha desempeñado funciones públicas destacadas en el Territorio Nacional o en el extranjero?',
      description:
        'Gobiernos Federales, Estatales, Municipales, funciones gubernamentales o judiciales, partidos políticos, personal militar de alto rango, altos ejecutivos de empresas estatales, etc.',
    },
    smsScreenTitle: 'SMS',
    smsScreenApplicationTitle: 'Código SMS',
    smsScreenChangePasswordTitle: 'Confirmación',
    smsInputLabel: 'Código SMS',
    smsLengthError: 'Ingresa tu código SMS',
    phoneInputDescription: 'El código de confirmación ha sido enviado a este número de teléfono',
    changePasswordInputDescription: 'Confirma el cambio de contraseña con el código de tu SMS',
    smsInputDescription: 'Ingresa el código de confirmación',
    phoneError: 'Ingresa número de teléfono',
    agreementsError: 'Requerido completar',
    resendTheCodeButton: 'Reenviar el código',
    resendTheCodeAfter: 'Reenviar el código en',
    smsCodeSentTo: 'Código SMS enviado a',
    clickingContinue: 'Al hacer clic en Continuar, aceptas nuestros',
    termsService: 'Términos de servicio',
    privacyPolicy: 'Política de privacidad',
    and: 'y la cláusula de medios electrónicos como PIN',
    byContinuingAccepting: '. Al continuar, aceptas la',
    consultation: 'consulta',
    ofYourCreditHistory: 'de tu historial crediticio.',
    buttonTitle: 'Continuar',
    buttonApplicationTitle: 'Confirmar',
    iHaveRead: 'He leído y acepto los Términos de Servicio y la Política de Privacidad',
    clickingConfirmYouAgree:
      'Al hacer clic en confirmar, acepta la cláusula de medios electrónicos como el PIN',
    iHaveReadPhoneScreen: 'He leído y acepto',
    andPhoneScreen: 'y',
    modalFirstTitle: 'Aviso de Seguridad:',
    modalFirstDescription:
      'Por favor, desconecta tu dispositivo de las redes Wi-Fi públicas, de lo contrario, un tercero podría robar tus datos personales',
    modalFirstButtonTitle: 'Entendido',
  },
  contactInfo: {
    title: 'Información de contacto',
    firstName: 'Nombre y segundo nombre',
    fatherLastName: 'Apellido paterno',
    motherLastName: 'Apellido materno',
    occupation: 'Ocupación',
    occupationPlaceholder: 'Seleccionar',
    curp: 'CURP',
    curpPlaceholder: '18 símbolos',
    email: 'Correo electrónico',
    emailPlaceholder: 'ejemplo@mail.com',
    requiredField: 'Campo obligatorio',
    patternError: 'Valor inválido',
  },
  result: {
    headerProcessingProblem: 'Procesamiento de la solicitud',
    buttonHome: 'Inicio',
    continue: 'Continuar',
    buttonUpdate: 'Actualizar',
    buttonLogIn: 'Iniciar sesión',
    buttonSupport: 'Soporte',
    ok: 'Ok',
    oops: 'Ups!',
    operationNotCompleted: 'No se pudo completar la operación',
    operationNotCompletedDescription:
      'Ups, algo salió mal. Inténtalo de nuevo. Si el problema persiste, contacta a nuestro equipo de soporte.',
    notCalculatorDate: 'Algo salió mal. Tenemos que arreglar algunas cosas. Vuelve más tarde.',
    somethingWentWrong: 'Algo salió mal. Inténtalo de nuevo más tarde',
    buttonRetry: 'Reintentar',
    operationWait: 'Solicitud de préstamo en revisión',
    operationWaitDescription: 'Por favor espera',
    successfully: 'Exitosamente',
    needRepeat: 'Necesita repetir',
    idCardSelfieIsNotSufficient:
      'El documento de identidad o el selfie no tienen la calidad suficiente',
    passSuccessfullyDesc: '¡Tu contraseña ha sido cambiada con éxito!',
    passSuccessfullyRecoveredDesc: '¡Tu contraseña ha sido recuperada exitosamente!',
    withinAFewMinutes: 'En unos minutos, el dinero se acreditará en su cuenta.',
    youHaveSuccessfullyRegistered:
      'Te has registrado exitosamente y tu solicitud de préstamo está en proceso',
    error: 'Error',
    passwordBlacklist:
      'La contraseña está en la lista de las más usadas. Crea una nueva contraseña',
    passwordContains: 'La contraseña no debe incluir el usuario. Crea una nueva contraseña',
    loanApplicationProcessInterruption: 'Interrupción del proceso de solicitud de préstamo',
    loanApplicationProcessHanding: 'Proceso de solicitud de préstamo',
    operationInProgress: 'Operación en progreso. Revisa el resultado más tarde',
    congratulations: '¡Felicidades!',
    referToYourAccount: 'Consulta tu cuenta para más detalles',
    processingRequestTakeSomeTime: 'El procesamiento de la solicitud puede tardar un poco',
    applicationInProcessing: 'Solicitud en proceso',
    pleaseWaitSituationDoesNotChange:
      'Por favor espera. Si la situación no cambia, escribe al soporte técnico',
    applicationNotCreated: 'Solicitud no creada',
    toCreateNewApplicationCancelPrevious:
      'Para crear una nueva solicitud, cancela la anterior o paga el préstamo',
    failedCreateApplication: 'No se pudo crear la solicitud de préstamo',
    contactUs: 'Contáctenos en ',
  },
  occupation: {
    title: 'Ocupación',
  },
  statesMunicipalities: {
    titleStates: 'Estado',
    titleMunicipalities: 'Municipios',
    placeOfBirth: 'Lugar de nacimiento',
    ne: 'Nacido en el extranjero',
  },
  createPassword: {
    title: 'Contraseña',
    subtitle: 'Por favor crea tu contraseña',
    description: 'Esta contraseña es necesaria para acceder a tu cuenta',
    password: 'Contraseña',
    confirmPassword: 'Confirmar contraseña',
    button: 'Crear',
    newPasswordThatDoNotMatch: 'Las contraseñas no coinciden',
    newPasswordLengthError: 'Mínimo 8 caracteres, mayúsculas y números',
    newPasswordMaxLengthError: 'Máximo 16 caracteres, mayúsculas y números',
    requiredPassword: '¡Por favor ingresa tu contraseña!',
    requiredConfirmPassword: '¡Por favor confirma tu contraseña!',
  },
  loanApplication: {
    title: 'Solicitud de préstamo',
    confirmButton: 'Confirmar',
    summary: 'Resumen',
    document: 'Documento',
    contactInformation: 'Información de contacto',
    payoutInformation: 'Información de pago',
    requestedAmount: 'Monto solicitado',
    repaymentAmount: 'Monto a pagar',
    term: 'Plazo',
    firstName: 'Nombre',
    fathersName: 'Apellido paterno',
    mothersName: 'Apellido materno',
    gender: 'Género',
    dateOfBirth: 'Fecha de nacimiento',
    stateOfBirth: 'Lugar de nacimiento',
    claveDeElector: 'Clave De Elector',
    curp: 'CURP',
    rfc: 'RFC',
    phoneNumber: 'Número de teléfono',
    email: 'Correo electrónico',
    requiredField: 'Campo obligatorio',
    clickingContinue: 'Al hacer clic en Continuar, aceptas solicitar tu',
    linkText: 'historial crediticio',
    destinationAccount: 'CLABE',
    bankAccount: 'Cuenta bancaria',
    paymentMethod: 'Método de pago',
  },
  documentUpload: {
    title: 'Escanea tu tarjeta INE',
    subTitle:
      'Para continuar con la solicitud de préstamo, escanea la tarjeta INE para verificar tu identidad.',
    button: 'Subir',
  },
  selfieUpload: {
    title: 'Verificación de selfie',
  },
  profilePage: {
    title: 'Perfil',
    docTitle: 'Documentos',
    docDesc: 'Lista de documentos del cliente',
    contactTitle: 'Datos de contacto',
    contactDesc: 'Número de teléfono, correo electrónico',
    settingTitle: 'Configuraciones',
    settingDesc: 'Contraseña, Face ID',
    logOutTitle: 'Cerrar sesión',
    logOutDesc: 'Los datos serán guardados',
    dialogApplicationTitle: 'Cerrar sesión',
    dialogApplicationDescription: '¿Estás seguro de que quieres cerrar sesión?',
    dialogCancelButton: 'Cancelar',
    dialogConfirmButton: 'Cerrar sesión',
    dialogDeleteButton: 'Eliminar',
    deleteProfileTitle: 'Eliminar perfil y datosa',
    deleteProfileDescription: 'Eliminar permanentemente todos los datos.',
    deleteProfileModalTitle: '¿Quieres eliminar tu perfil y datos?',
    deleteProfileModalDescription:
      'Al enviar una solicitud para eliminar todos tus datos personales y perfil, ya no recibirás ninguna oferta especial ni promociones de nuestra parte. Sin embargo, debido a los requisitos regulatorios, no podemos eliminar la información que se encuentra en fuentes oficiales, como burós de crédito. Si decides continuar con la eliminación de tu perfil, presiona el botón “Eliminar”. Procesaremos tu solicitud lo antes posible y te enviaremos una notificación.',
    dialogUnsubscribeTitle: '¿Darse de baja de las campañas de marketing?',
    dialogUnsubscribeDescription:
      'Si cancela su suscripción a nuestras actividades de marketing, ya no recibirá nuestras mejores ofertas y promociones. En cuanto recibamos su solicitud, la procesaremos lo antes posible',
    dialogUnsubscribeConfirm: 'Darse de baja',
  },
  loan: {
    title: 'Préstamo',
  },
  application: {
    titlePage: 'Historia',
    title: 'Solicitud',
    cancelButton: 'Cancelar',
    continueButton: 'Continuar',
    cancelLoanButton: 'Cancelar solicitud de préstamo',
    noHistoryDesc:
      'Todavía no hay nada aquí, pero tu historial aparecerá después de que envíes una solicitud.',
    errorHistoryDesc: 'No se pudo cargar el historial. Inténtalo de nuevo más tarde',
    draft: 'Borrador',
    onReview: 'En revisión',
    approved: 'Aprobado',
    rejected: 'Rechazado',
    cancelled: 'Cancelado',
    expired: 'Expirado',
    issued: 'Emitido',
    active: 'Activo',
    overdue: 'Atrasado',
    paid: 'Pagado',
    closed: 'Cerrado',
    nextPayment: 'Próximo pago',
    totalPaid: 'Importe total pagado',
    creationDate: 'Fecha de creación',
    nextPaymentDate: 'Fecha',
    closedDate: 'Fecha de cierre',
    requestedAmount: 'Monto solicitado',
    term: 'Plazo',
    availableUntil: 'Disponible hasta',
    paymentDetails: 'Detalles de pago',
    loanAppl: 'Solicitud de préstamo',
  },
  id: {
    titlePersonal: 'Información personal',
    titleID: 'Identificación',
    titleAddress: 'Dirección',
    buttonTitle: 'Confirmar',
    firstName: 'Nombre y segundo nombre',
    fathersName: 'Apellido paterno',
    mothersName: 'Apellido materno (opcional)',
    mothersNamePlaceholder: 'Apellido materno',
    gender: 'Género',
    dateOfBirth: 'Fecha de nacimiento',
    dateOfBirthPlaceholder: 'Seleccionar',
    claveElector: 'Clave De Elector',
    claveElectorPlaceholder: '18 símbolos',
    curp: 'CURP',
    curpPlaceholder: '18 símbolos',
    rfc: 'RFC',
    rfcPlaceholder: '13 símbolos',
    occupation: 'Ocupación',
    email: 'Correo electrónico',
    occupationPlaceholder: 'Seleccionar',
    placeOfBirth: 'Lugar de nacimiento',
    state: 'Estado',
    municipality: 'Municipio',
    city: 'Ciudad',
    region: 'Colonia',
    street: 'Calle',
    postcode: 'Código postal',
    requiredField: 'campo obligatorio',
    rfcLengthError: 'contiene 13 caracteres',
    emailError: 'ejemplo@mail.com',
    cancelButton: 'Cancelar',
    okButton: 'Seguro',
    january: 'Enero',
    february: 'Febrero',
    march: 'Marzo',
    april: 'Abril',
    may: 'Mayo',
    june: 'Junio',
    july: 'Julio',
    august: 'Agosto',
    september: 'Septiembre',
    october: 'Octubre',
    november: 'Noviembre',
    december: 'Diciembre',
    howToFindClaveDeElector: '¿Cómo saber la Clave de Elector?',
    howToFindCurp: '¿Cómo saber la CURP?',
    orOnWebsite: 'O en el sitio web',
    consultaTuCurp: 'Consulta tu CURP.',
    howToFindRfc: '¿Cómo saber el RFC?',
    onTheWebsite: 'En el sitio web',
    consultaTuRfc: 'Consulta tu RFC.',
  },
  introScreen: {
    title: 'Verificación',
    buttonTitle: 'Comenzar',
    buttonTitleNoNubariumStepOne: 'Imitación nubarium, paso 1',
    buttonTitleNoNubariumStepTwo: 'Imitación nubarium, paso 2',
    buttonTitleNoJumio: 'Imitación jumio',
    subtitle: 'Para confirmar tu identidad, realiza lo siguiente',
    takePhotoIne: 'Toma una foto de tu INE',
    takePhotoFace: 'Toma una foto de tu rostro',
    verifyData: 'Verifica tus datos',
    verifyDataEnrichment: 'Esté preparado para completar su información',
  },
  infoBlock: {
    days: 'días',
  },
  processingApp: {
    title: 'Procesando tu solicitud',
  },
  yourApplication: {
    titleApproved: '¡Tu préstamo ha sido aprobado!',
    titleLoading: 'Procesando tu solicitud',
    summary: 'Resumen',
    docSubtitle: 'Aquí están tus documentos legales',
    holdOn: 'Por favor, espera',
    textContent1:
      'Estamos procesando tu solicitud de préstamo, este proceso puede tardar entre 1 minuto y 1 hora.',
    textContent2:
      'Para asegurar que el proceso no sea interrumpido, no cierres esta ventana. Agradecemos tu paciencia mientras trabajamos para acelerar el proceso.',
    requestedAmount: 'Monto solicitado',
    approvedAmount: 'Monto aprobado',
    term: 'Plazo',
    repaymentAmount: 'Monto a pagar',
    repaymentDate: 'Fecha de pago',
    paymentSchedule: 'Cronograma de pagos',
    loanArgeement: 'Contrato de préstamo',
    amortizationTable: 'Tabla de amortization',
    pagare: 'Pagare',
    annexOfLegalProvisions: 'Annexo de disposiciones legales',
    needConfirmText: 'Debes confirmar tu contrato con el código SMS',
    iConfirmText: 'Confirmo que he leído el contrato y estoy listo para firmarlo',
    sendButton: 'Obtener código SMS',
    cancelButton: 'Cancelar',
    geolocationError: 'La geolocalización no es compatible',
    geolocationError1: 'PERMISO DENEGADO',
    geolocationError2: 'POSICIÓN NO DISPONIBLE',
    geolocationError3: 'El tiempo de espera expiró',
    byClickingGetSms:
      'Al hacer clic en Obtener código SMS, confirmas que has leído el contrato y estás listo para firmarlo',
    dialogApplicationTitle: 'Cancelar solicitud',
    dialogApplicationDescription: '¿Estás seguro de que quieres cancelar tu solicitud de préstamo?',
    dialogConfirmButton: 'Sí, Cancelar',
    dialogCancelButton: 'No, Continuar',
    geolocationHelp:
      'Asegúrate de que el GPS esté activado y de que los permisos de geolocalización estén habilitados en la configuración de tu dispositivo',
    geolocationHelpTitle: 'No se puede acceder a la geolocalización',
  },
  requestStatus: {
    expired: {
      title: 'Expirado',
      description: 'La solicitud de préstamo ha expirado. ¿Te gustaría crear una nueva?',
    },
    cancelled: {
      title: 'Cancelado',
      description: 'La solicitud de préstamo ha sido cancelada. ¿Te gustaría crear una nueva?',
    },
    rejected: {
      title: 'Rechazado',
      description:
        'Vaya... Desafortunadamente no podemos aprobar este préstamo, ¡intenta con uno de nuestros socios!',
      descriptionRejectedNoPartners:
        'Se rechaza la solicitud de préstamo. Puedes cambiar los parámetros del préstamo y volver a intentarlo.',
    },
    moratorium: {
      title: '¡Ups!',
      description:
        'Lo sentimos, pero por el momento no podemos ofrecerle un préstamo. Vuelve pronto y vuelve a intentarlo 🙂',
    },
    buttonText: 'Inicio',
  },
  duplicatePhoneNumber: {
    title: 'Este número de teléfono ya está registrado',
    subTitle: 'Por favor, inicia sesión o contacta al soporte para asistencia.',
  },
  loanAppDetailsPage: {
    title: 'Detalles del préstamo',
  },
  destinationAccountPage: {
    destinationAccount: 'Cuenta de destino',
    buttonTitle: 'Confirmar',
    depositErrorMessage: 'Número de cuenta incorrecto',
    label: 'Ingresa la cuenta de depósito (CLABE)',
    placeholder: '18 dígitos',
    paymentMethod: 'Método de pago',
    bankAccount: 'Cuenta bancaria',
  },
  loanWidget: {
    titleLoan: 'Número de préstamo',
    titleApplication: 'Solicitud de préstamo',
    buttonTitle: 'Continuar',
    requestedAmount: 'Monto solicitado',
    creationDate: 'Fecha de creación',
    term: 'Plazo',
    days: 'días',
    availableUntil: 'Disponible hasta',
    altImage: 'Dinero',
    dialogTitle: 'Interrupción del proceso de solicitud de préstamo',
    dialogDescription: 'Estás a punto de interrumpir tu proceso de solicitud de préstamo.',
    dialogCancelButton: 'Cancelar',
    dialogConfirmButton: 'Descartar',
    proceedToUpload: 'Proceder a cargar',
  },
  screenFooter: {
    home: 'Inicio',
    history: 'Historial',
    chat: 'Charlar',
    profile: 'Perfil',
  },
  progressBar: {
    enterPhoneNumber: 'Dinos cómo contactarte y acercar tus objetivos',
    confirmPhone: 'Confirma tu teléfono',
    createPassword: 'Seamos seguros, crea una contraseña',
    turnYourCamera: 'Ya casi, enciende la cámara y tomemos una foto',
    checkYourInfo: 'Casi listo, revisemos tu información',
    checkYourAddress: 'Una cosa más, revisa/llena tu dirección',
    lastOne: 'La última, transferiremos tu préstamo aquí',
    checkYourApplication: 'Listo, revisemos tu solicitud',
    yourLoanApproved: '¡Wow, tu préstamo fue aprobado, confirmémoslo!',
    lastOneStep: 'El último paso',
  },
  personalInfo: {
    title: 'Información personal',
    mainInformation: 'Información principal',
    firstName: 'Nombre',
    fathersName: 'Apellido paterno',
    mothersName: 'Apellido materno',
    gender: 'Género',
    dateOfBirth: 'Fecha de nacimiento',
    placeOfBirth: 'Lugar de nacimiento',
    claveElector: 'Clave De Elector',
    curp: 'CURP',
    rfc: 'RFC',
    address: 'Dirección',
    postcode: 'Código postal',
    state: 'Estado',
    municipality: 'Municipio',
    city: 'Ciudad',
    region: 'Región',
    street: 'Calle',
    payoutInformation: 'Información de pago',
    destinationAccount: 'Cuenta de destino',
    bankAccount: 'Cuenta bancaria',
    clabe: 'CLABE',
  },
  contactData: {
    title: 'Datos de contacto',
    phoneNumber: 'Número de teléfono',
    email: 'Correo electrónico',
  },
  settingsPage: {
    installed: 'Instalado',
    notInstalled: 'No instalado',
    installedInstead: 'Usado en lugar de un código PIN',
    title: 'Configuraciones',
    password: 'Contraseña',
    pin: 'PIN code',
    biometricAuthentication: 'Autenticación biométrica',
    unsubscribeTitle: 'Darse de baja de las campañas de marketing',
    unsubscribeDescription: 'No recibirás ninguna comunicación de marketing',
  },
  changePasswordPage: {
    title: 'Cambiar contraseña',
    currentPassword: 'Contraseña actual',
    currentPasswordPlaceholder: 'Ingresa tu contraseña actual',
    subtitle: 'Nueva contraseña',
    createPassword: 'Crea una contraseña',
    createPasswordPlaceHolder: 'Ingresa tu nueva contraseña',
    confirmPassword: 'Confirmar contraseña',
    buttonTitle: 'Confirmar',
  },
  appStatus: {
    draft: 'BORRADOR',
    onReview: 'EN_REVISIÓN',
    approved: 'APROBADO',
    rejected: 'RECHAZADO',
    cancelled: 'CANCELADO',
    expired: 'EXPIRADO',
  },
  gender: {
    mujer: 'Mujer',
    hombre: 'Hombre',
  },
  alertMessage: {
    errorLoginForbidden: 'No tienes derechos de acceso',
    errorOther: 'Algo salió mal',
    warningContactAdmin: 'Contacta a tu administrador para restaurar el acceso',
    infoSmsSend: 'Código SMS enviado',
    errorLogin: 'Usuario o contraseña incorrectos',
    errorSmsSend: 'Código SMS no enviado. Intenta más tarde',
    errorSmsSendBlock: 'Un nuevo código SMS puede generarse en xx min/seg',
    errorWrongOtp: 'Código incorrecto',
    invalidPassword: 'Contraseña inválida',
    errorCodeExpired: 'El código ha expirado. Solicita un nuevo código',
    errorCodeUnable: 'No se puede registrar. Contacta al soporte técnico',
    errorCodeExceeded:
      'Código inválido. Se ha superado el número de intentos para ingresar el código de verificación. Solicita un nuevo código',
    alreadyRegistered: 'Un usuario con este número de teléfono ya está registrado',
    passwordBlacklist: 'La contraseña está en la lista de las más usadas. Crea otra contraseña',
    passwordContainsUsername: 'La contraseña no debe incluir el usuario. Crea otra contraseña',
    passwordIncorrect: 'Mínimo 8 caracteres, mayúsculas y números',
    badRequest: 'Solicitud incorrecta (400)',
    checkValue: 'Verifica el valor',
    timeoutExceeded: 'Se excedió el tiempo límite',
    checkStatusSupport: 'Verifica el estado de la solicitud o contacta al soporte',
    incorrectDataSupport: 'Datos incorrectos. Contacta al soporte',
    emailNotSet: 'Correo no establecido. Debe configurarse en el perfil',
    temporaryProblems: 'Problemas temporales. Inténtalo más tarde',
    temporaryErrorOccurred: 'Ocurrió un error temporal. Inténtalo más tarde',
    http400: 'Http 400',
    smsCodeSent: 'Código SMS enviado a',
    failedVerifySms: 'No se pudo verificar el código SMS. Inténtalo más tarde',
    incorrectAccountNumber: 'Número de cuenta incorrecto',
    makeSureAccount: 'Por favor, asegúrate de que la cuenta que ingresaste te pertenece',
    invalidPhoneNumber: 'Número de teléfono inválido',
    checkAccuracyEnteredCurp: 'Por favor verifique la exactitud del número CURP ingresado',
    newPasswordMustNotMatch:
      'La nueva contraseña no debe coincidir con ninguna de las tres contraseñas anteriores. Cree otra contraseña',
    youAlreadyRequestedProfileDeletion:
      'Ya has solicitado la eliminación del perfil. Por favor espera.',
    userWithCurpNumber: 'Usuario con número CURP',
    alreadyExistsConnectedToPhone: 'ya existe y está conectado al número de teléfono',
    pleaseLoginIntoYourAccount: '. Por favor, inicie sesión en su cuenta.',
    youHaveSuccessfullyUnsubscribed: 'Te has dado de baja con éxito de las campañas de marketing',
  },
  screenAccount: {
    hello: 'Hola!',
  },
  stepBar: {
    verify: 'Verificar',
    signUp: 'Registrarse',
    document: 'Documento',
    result: 'Resultado',
  },
  confirmEmail: {
    successfullyDescription:
      'Hola, ¡Has confirmado tu correo electrónico con éxito! Ahora tienes más disponible en la app de Monetech',
    errorDescription: 'Algo salió mal :( Tu enlace ya no es válido',
  },
  partnersBlock: {
    buttonTitle: 'Llévame',
  },
  pdfViewer: {
    prev: 'Vista previa',
    next: 'Siguiente',
    page: 'Page',
    from: 'from',
    download: 'Descargar PDF',
  },
  chatPage: {
    title: 'Charlar',
  },
  nubariumErrorDialog: {
    title: 'No se pudieron cargar los datos',
    description:
      'Encontramos un problema al cargar los datos. Asegúrese de que el documento esté bien iluminado y estacionario.',
    cancelButton: 'Cancelar',
    tryAgainButton: 'Intentar otra vez',
  },
  pdfDocTitles: {
    loan_agreement: 'Contrato de préstamo',
    payment_schedule: 'Calendario de pagos',
  },
  documents: 'Documentos',
  loanInfo: {
    title: 'Pago del préstamo',
    repaymentAmount: 'Monto del reembolso',
    interestAmount: 'Monto de interés',
    commissionAmount: 'Comisión por reembolso anticipado',
    loanAmount: 'Monto del préstamo',
    overdue: 'Cargo vencido',
    closingDate: 'Fecha de cierre',
    repaymentDate: 'Fecha de reembolso',
    paidAmount: 'Importe pagado',
    payDetailsStepTitle:
      'Para pagar el préstamo simplemente realice una transferencia de dinero a nuestra cuenta mediante los siguientes datos de cuenta:',
    purposeStepTitle: 'Finalidad del pago:',
    purposeStepText: 'Pago del contrato de préstamo',
    inbursaStepTitle: 'Si deseas pagar a través de Inbursa',
    inbursaStepText: 'Cuenta Inbursa',
    noticeStepTitle: 'Aviso importante:',
    repaymentSummary: 'Resumen del reembolso:',
    noticeStepText:
      'Debe copiar con cuidado la información anterior y no cambiar nada, de lo contrario, su pago se retrasará y pasará a la categoría de vencido.',
    repayBtn: 'Pagar',
  },
  faq: {
    title: 'FAQ',
  },
  copied: '¡Copiado exitosamente!',
  recoveryPassword: {
    title: 'Recuperación de contraseña',
    titleNewPassword: 'Establecer una nueva contraseña',
    toResetPasswordEnterCurp: 'Para restablecer su contraseña, ingrese su número CURP',
  },
  pin: {
    pageTitle: 'Código PIN',
    subtitle: 'Crea una clave de acceso para facilitar tu inicio de sesión la próxima vez:',
    desc1: 'Una clave de acceso es una forma segura y simple de volver a tu cuenta',
    desc2: 'Úsala con Face ID o reconocimiento de huella digital para un acceso rápido',
    desc3: 'Tu clave de acceso está almacenada de forma segura',
    create: 'Crear código PIN',
    edit: 'Editar código PIN',
    repeat: 'Repetir código PIN',
    disable: 'Desactivar código PIN',
    current: 'Código PIN actual',
    passTitle: 'Contraseña',
    passText: 'Por favor, introduce la contraseña de tu cuenta para configurar un código PIN',
    confirm: 'Confirmar',
    cancel: 'Cancelar',
    passErrRequired: 'Introduce la contraseña',
    passInvalid: 'Contraseña inválida',
    successTitle: 'Éxito',
    pinErrMatch: 'El código PIN no coincide. Inténtalo de nuevo',
    pinErr: 'Código PIN incorrecto. Inténtalo de nuevo',
    successText:
      'El código PIN se ha configurado correctamente. Ahora puedes usar tu código PIN para iniciar sesión rápidamente en la aplicación.',
    successTextEdit:
      'El código PIN se ha cambiado correctamente. Ahora puedes usar tu nuevo PIN para iniciar sesión rápidamente en la aplicación.',
    disableTitle: 'Desactivar código PIN',
    disableText: '¿Estás seguro de que quieres desactivar el código PIN?',
    loginByPassword: 'Iniciar sesión con contraseña',
    attempts: 'más intentos restantes',
  },
  inputWarnings: {
    pleaseEnsureFirstName: 'Asegúrese de que su nombre esté escrito correctamente',
    pleaseEnsureFathersName: 'Asegúrese de que el nombre de su padre esté escrito correctamente',
    pleaseEnsureMothersName: 'Asegúrese de que el nombre de su madre esté escrito correctamente',
  },
  customSpin: {
    spinDescription1: 'Por favor, espere.',
    spinDescription2: 'Está tardando un poco más.',
  },
  additionalDataScreen: {
    title: 'Datos adicionales',
    subtitle: 'Proporcione información adicional para completar su solicitud de préstamo:',
    ineFrontPhoto: 'Foto de portada del INE',
    ineBackPhoto: 'Foto de la parte posterior del INE',
    yourself: 'Una foto tuya',
    proofAddressPhoto: 'Foto de comprobante de domicilio',
    frontSideTitle: 'Parte frontal de la tarjeta INE',
    frontSideDescription:
      'Asegúrese de tener un lugar con buenas condiciones de luz, de que sus documentos sean bien visibles a través de la cámara y limpie la cámara',
    backSideTitle: 'Parte posterior de la tarjeta INE',
    selfieCheckTitle: 'Comprobación de selfies',
    selfieCheckDescription:
      'Asegúrate de tener buena iluminación, para que podamos ver tu rostro claramente y limpia la lente de tu cámara.',
    proofAddressTitle: 'Documento de comprobante de domicilio',
    proofAddressDescription:
      'Para verificar la dirección, fotografíe o cargue un escaneo/foto del documento correspondiente.',
    retakeButton: 'Volver a tomar',
    continueButton: 'Continuar',
  },
};
