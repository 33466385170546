export enum NextStep {
  registration = 'registration',
  nubarium = 'nubarium',
  jumio = 'jumio',
  wait = 'wait',
  cancelled = 'cancelled',
  processingApp = 'processingApp',
  expired = 'expired',
  rejected = 'rejected',
  moratorium = 'moratorium',
  success = 'success',
  payoutDestination = 'payoutDestination',
  reviewAppData = 'reviewAppData',
  approveProposal = 'approveProposal',
  nextFormIntoScenario = 'nextFormIntoScenario',
  underwriting = 'underwriting',
  enrichment = 'enrichment',
  otp = 'OTP',
  curp = 'CURP',
  createPassword = 'createPassword',
}
