import { useVisitorData } from '@fingerprintjs/fingerprintjs-pro-react';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { LOCAL_STORAGE } from '../constants/local-storage';
import { isWebContext } from '../utils/is-web-context';
import { getEnvUrl } from '../utils/get-env-url';
import { env } from '../constants/app-url';
import { showOnStand, StandEnum } from '../utils/show-on-stand';

export const useFingerprint = () => {
  const { isLoading, data, getData } = useVisitorData({ ignoreCache: true }, { immediate: false });
  const [params] = useSearchParams();

  useEffect(() => {
    const visitorInParams = params.get('fingerprint_visitor_id');
    const visitorId = localStorage.getItem(LOCAL_STORAGE.xFingerprintVisitorId);
    const { currentEnv } = getEnvUrl();
    const startReq = ![env.DEV, env.LOCAL, env.LOCAL_MOBILE].includes(currentEnv);

    if (startReq && isWebContext && !data && !visitorId && !visitorInParams) {
      getData().then((visData) => {
        localStorage.setItem(LOCAL_STORAGE.xFingerprintVisitorId, visData.visitorId);
      });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const isApprovedScreenShown = localStorage.getItem(LOCAL_STORAGE.isApprovedScreenShown);
    const isNewClient = sessionStorage.getItem(LOCAL_STORAGE.isNewClient);
    const isDev = showOnStand({ stands: [StandEnum.DEV, StandEnum.LOCAL] });
    const analyticsDev = sessionStorage.getItem(LOCAL_STORAGE.analytics);

    if (!isApprovedScreenShown) {
      localStorage.setItem(LOCAL_STORAGE.isApprovedScreenShown, 'false');
    }
    if (!isNewClient) {
      sessionStorage.setItem(LOCAL_STORAGE.isNewClient, 'true');
    }
    if (isDev && !analyticsDev) {
      sessionStorage.setItem(LOCAL_STORAGE.analytics, 'false');
    }
  }, []);

  return { isLoading };
};
