export enum StandEnum {
  DEV = 'https://pwa.dev.monetech.mx',
  LOCAL = 'http://localhost',
}

type Props = {
  stands: StandEnum[];
};

const checkStorageValues = (isSession: boolean, obj: { [key in string]: string }) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of Object.entries(obj)) {
    const storedValue = isSession ? sessionStorage.getItem(key) : localStorage.getItem(key);

    if (storedValue !== value) {
      return false;
    }
  }

  return true;
};

export const showOnStand = ({ stands }: Props) => {
  if (typeof window !== 'undefined') {
    const currentLocation = window.location.href as StandEnum;

    return stands.some((i) => currentLocation.startsWith(i));
  }

  return false;
};

export const showAnalytics = () => {
  const isDev = showOnStand({ stands: [StandEnum.LOCAL, StandEnum.DEV] });

  if (isDev) {
    return checkStorageValues(true, { analytics: 'true' });
  }

  return true;
};
