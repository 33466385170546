import React from 'react';
import ReactDOM from 'react-dom/client';
import './i18n';
import { FpjsProvider, FingerprintJSPro } from '@fingerprintjs/fingerprintjs-pro-react';
import TagManager from 'react-gtm-module';

import { reportWebVitals } from './report-web-vitals';
import { App } from './app';
import './index.css';
import AppsFlyer from './utils/appsflyer';
import { showAnalytics } from './utils/show-on-stand';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const tagManagerArgs = {
  gtmId: 'GTM-P7T6KZT5',
};

if (showAnalytics()) {
  TagManager.initialize(tagManagerArgs);
}

AppsFlyer.initAppsflyer();

root.render(
  <React.StrictMode>
    <FpjsProvider
      loadOptions={{
        apiKey: 'qusZU7vcyMQJ2m5s6ZQD',
        endpoint: ['https://fp.monetechservicios.com', FingerprintJSPro.defaultEndpoint],
        scriptUrlPattern: [
          'https://fp.monetechservicios.com/web/v<version>/<apiKey>/loader_v<loaderVersion>.js',
          FingerprintJSPro.defaultScriptUrlPattern,
        ],
        // region: "eu"
      }}
    >
      <App />
    </FpjsProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
